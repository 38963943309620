<template>
  <div class="recharge-page">
    <headerBar :title="i18n.t('recharge.title')" class="red" :showArrow="true" @back="back"></headerBar>
    <template v-if="isBank">
      <div class="recharge-form">
        <div class="label">{{i18n.t('recharge.name')}}:
          {{ BankInfo.RealName }}
          <van-button plain type="danger" @click="copyToClipboard(BankInfo.RealName)">{{  i18n.t("recharge.copy1") }}</van-button></div>
        <div class="label"></div>
      </div>
      <div class="recharge-form">
        <div class="label">{{i18n.t('recharge.bankName')}}:
          {{ BankInfo.Company }}
          <van-button plain type="danger" @click="copyToClipboard(BankInfo.Company)">{{  i18n.t("recharge.copy1") }}</van-button></div>
        <div class="label"></div>
      </div>
      <div class="recharge-form">
        <div class="label">{{i18n.t('recharge.bankCard')}}:
          {{ BankInfo.Card }}
          <van-button plain type="danger" @click="copyToClipboard(BankInfo.Card)">{{  i18n.t("recharge.copy1") }}</van-button></div>
        <div class="label"></div>
      </div>
      <div class="recharge-form" v-if="type == 'Chile'">
        <div class="label">{{i18n.t('bind.TaxID')}}:
          {{ BankInfo.TaxID }}
          <van-button plain type="danger" @click="copyToClipboard(BankInfo.TaxID)">{{  i18n.t("recharge.copy1") }}</van-button></div>
        <div class="label"></div>
        <div class="label">{{i18n.t('bind.Type')}}:
          {{ BankInfo.Type }}
          <van-button plain type="danger" @click="copyToClipboard(BankInfo.Type)">{{  i18n.t("recharge.copy1") }}</van-button></div>
        <div class="label"></div>
      </div>
      <div class="recharge-form">
        <div class="label">{{i18n.t('recharge.label4')}}</div>
        <div class="input">
          <input type="number" v-model="form.number" readonly :placeholder="i18n.t('recharge.placeholder')">
          <template v-if="type === 'Mexico'">
            <label v-if="exchange_rate > 0">{{i18n.t('recharge.exchange')}}：$1 ≈ {{ exchange_rate }} MXN</label> <br />
            <label v-if="exchange_rate > 0 && form.number > 0">{{i18n.t('recharge.Exchange_amount')}}：  {{ exchange(exchange_rate) }} MXN</label>
          </template>
          <template v-if="type === 'Chile'">
            <label v-if="chile_exchange_rate > 0">{{i18n.t('recharge.exchange')}}：$1 ≈ {{ chile_exchange_rate }} CLP</label> <br />
            <label v-if="chile_exchange_rate > 0 && form.number > 0">{{i18n.t('recharge.Exchange_amount')}}：  {{ exchange(chile_exchange_rate) }} CLP</label>
          </template>
        </div>
      </div>
      <br />
      <div class="recharge-form">
        <div class="label">{{i18n.t('recharge.label6')}}</div>
        <div>
          <van-uploader v-model="fileList" max-count="1" :after-read="afterRead" />
        </div>
      </div>
      <br />
      <br />
    </template>
    <template v-else>
      <van-grid :column-num="1">
        <van-grid-item icon="photo-o" >
          <div class="qrcode" ref="qrcode" ></div>
        </van-grid-item>
      </van-grid>
      <div class="recharge-form">
        <div class="label">{{ RechargeInfo.protocol}} {{i18n.t('recharge.address')}}</div>
        <div class="label">{{ RechargeInfo.address }}</div>
        <van-button plain type="danger" @click="copyToClipboard(RechargeInfo.address)">{{  i18n.t("recharge.copy") }}</van-button>
      </div>

      <div class="recharge-form">
        <div class="label">{{i18n.t('recharge.label6')}}</div>
        <div>
          <van-uploader v-model="fileList" max-count="1" :after-read="afterRead" />
        </div>
      </div>
      <br />
      <br />
    </template>

    <div class="btn">
      <Button @click="submit">{{i18n.t('recharge.btn1')}}</Button>
    </div>
  </div>
</template>
<script>
import { Button } from 'vant'
import headerBar from '@/components/header'
import { doRecharge, uploadFile } from '@/api/user'
import QRCode from "qrcodejs2";
export default {
  components: {
    headerBar,
    Button,
  },
  data() {
    return {
      form: {
        number: '',
        type: 1,
        img:""
      },
      userMoney: 0,
      fastList: [50, 100, 200, 300, 500, 600, 800, 1000, 2000, 5000, 8000, 10000],
      address: '',
      totalAmount: 0,
      RechargeList: {},
      fileList: [],
      RechargeInfo: {
        protocol: "",
        address: ""
      },
      BankInfo: {},
      type: "",
      isBank : false,
      totalAmountWait: 0,
      exchange_result: 0,

    }
  },
  computed: {
    show_collection() {
      return this.$store.state.show_collection
    },
    chile_exchange_rate(){
      return this.$store.state.chile_exchange_rate
    },
    exchange_rate() {
      return this.$store.state.exchange_rate
    },
  },
  created() {
    this.type = this.$route.query.type
    if(this.type === undefined) {
      this.type = window.localStorage.getItem("type")
    }
    // type = Italia
    if(this.type.toString().toLocaleLowerCase() === "bank" || this.type.toString().toLocaleLowerCase() === "wise" ) {
      // 跳转到客服页面
      this.$router.push({
        name: 'kefu',
      })
    }
    this.form.number = this.$route.query.amount
    if (this.form.number === undefined) {
      this.form.number = window.localStorage.getItem("amount")
    }
    // this.exchange_result = parseFloat(this.$route.query.amount * this.exchange_rate).toFixed(2)
    let that = this
    switch (this.$route.query.type) {
      case "Mexico":
      case "Chile":
        this.isBank = true
        let tmp = JSON.parse(window.localStorage.getItem("BankInfo"))
          tmp.forEach(item => {
            if(item.Country === this.$route.query.type) {
              that.BankInfo = item
            }
          })
        // this.BankInfo = JSON.parse(window.localStorage.getItem("BankInfo"))

        break
      default:
        this.RechargeList = JSON.parse(window.localStorage.getItem("RechargeInfo"))
        this.RechargeInfo = this.RechargeList.filter(item => item.protocol === this.$route.query.type)[0]
            break
    }

  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      let address = ""
      if(this.RechargeInfo) {
        address = this.RechargeInfo.address
      }
      if( this.type !== "bank") {
        let qrcode = new QRCode(this.$refs.qrcode, {
          text: address,
          width: 250,
          height: 250,
          correctLevel: QRCode.CorrectLevel.H
        })
      }
    },
    // 计算兑换金额
    exchange(exchange_rate) {
      return (this.form.number * exchange_rate).toFixed(2)
    },
    copyToClipboard(data) {
      let that = this
      // const clipboard = new Clipboard('.copy-icon', {text: () => data});
      this.$copyText(data).then(function (e) {
        that.$toast(that.i18n.t('recharge.success2'))
      }, function (e) {
        console.log(e)
      })
      // new VueClipboard2('.copy-to-clipboard', {
      //   successMessage: data
      // }).on('success', () => {
      //   this.$toast(this.i18n.t('recharge.success2'))
      // });
    },
    afterRead(file) {
      // 创建 FormData 对象
      var formData = new FormData();
      formData.append('file', file.file);
      uploadFile(formData).then(res => {
        this.form.img = res.data.url
        this.fileList = [
          { url: "/upload/" + this.form.img}
        ]
      })
      // 此时可以自行将文件上传至服务器
      console.log(file);
    },
    back() {
      this.$router.go(-1)
    },
    setMoney(money) {
      this.form.number =  money
    },
    goToRecharge(){
      if(this.form.img === "") {
        this.$toast(this.i18n.t('recharge.error1'))
        return
      }
      doRecharge({
        amount:this.$route.query.amount + "",
        payment_voucher: this.form.img
      }).then(res => {
        if (res.code == 0) {
          this.$toast(this.i18n.t('recharge.success1'))
          this.$router.push({
            name: 'index',
          })
        } else {
          this.$toast(res.msg)
        }
      })
    },
    submit() {
      this.goToRecharge()
    }
  }
}
</script>