<template>
  <div class="download-page">
    <div class="title">Welcome to {{appName}} Club</div>

    <div class="btn">
      <Button class="btn1 " @click="download('ios')">
        <img :src="iosUrl" alt="" class="icon">
        Apple download
      </Button>
    </div>
    <div class="btn">
      <Button class="btn2 " @click="download('android')">
        <img :src="androidUrl" alt="" class="icon">
        Android download
      </Button>
    </div>
    <div class="btn">
      <Button class="btn2 " @click="toHome">
        <img :src="webUrl" alt="" class="icon">
        Web Login
      </Button>
    </div>
  </div>
</template>
<script>
import { Button } from 'vant'
export default {
  components: {
    Button
  },
  data() {
    return {
      iosUrl: require('../../assets/imgs/icon-ios.png'),
      androidUrl: require('../../assets/imgs/icon-android.png'),
      webUrl: require('../../assets/imgs/icon-web.png'),
    }
  },
  computed: {
    iosDownloadUrl() {
      return this.$store.state.iosDownloadUrl
    },
    androidDownloadUrl() {
      return this.$store.state.androidDownloadUrl
    },
    appName(){
      return this.$store.state.appName
    }
  },
  methods: {
    toHome() {
      this.$router.push({
        name: 'index'
      })
    },
    download(type) {

      let url = type == 'ios' ? this.iosDownloadUrl : this.androidDownloadUrl
      // 检查是否有http://或https://开头
      if (!url.startsWith('http')) {
        url = "/upload/" + url
      }
      // 增加时间戳，防止浏览器缓存
      url += '?t=' + new Date().getTime()
      console.log("url:",url)
      let a = document.createElement('a')
      a.href = url
      a.target = '_blank'
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    }
  }
}
</script>