<template>
  <div class="mine-container">
    <div class="mine-top flex-center-between">
      <div>
        <div class="header" :class="`header-${avatar}`" @click="toPath('header')"></div>
      </div>
      <div class="flex-center-between flex-1">
        <div class="">
          <div class="flex-center">
            <span class="name">{{userName}}</span>
            <span :class="'level' + ' leave' + levelName" >VIP{{levelName}}</span>
<!--            <span class="level">-->
<!--              <img :src="vip[0]" style="width: 1.8rem" />-->
<!--            </span>-->
          </div>
          <div class="xinyu">
            {{ appName }} {{i18n.t('mine.label1')}}:👑
          </div>
        </div>
        <div class="text-right">
          <div class="lang" @click="toLang">
            <img :src="langIcon" alt="" v-show="langIcon">

            <div class="lang-list" v-show="showLang">
              <div class="list" v-for="(item, i) in langList" :key="i" @click.stop="changeLang(item)">
                <img :src="item.icon" alt="">
              </div>
            </div>
          </div>
          <div class="phone">
            <i class="iconfont icon-phone"></i>
            {{userPhone}}
          </div>
        </div>
      </div>
    </div>
    <div class="mine-main">
      <div class="label">{{i18n.t('mine.label2')}}</div>
      <div class="money">$ {{parseFloat(userMoney).toFixed(2)}}</div>
      <div class="tab-box flex-center-between">
        <div class="tab flex-column-center red" @click="toPath('recharge')">
          <img :src="rechargeUrl" alt="">
          <span>{{i18n.t('mine.label3')}}</span>
        </div>
        <div class="tab flex-column-center green" @click="toPath('withdraw')">
          <img :src="withdrawUrl" alt="">
          <span>{{i18n.t('mine.label4')}}</span>
        </div>
      </div>
    </div>

    <div class="menu-list">
      <div class="list flex-center" v-for="(item, i) in menuList" :key="i" @click="toPath(item.path)">
        <div class="icon">
          <i class="iconfont" :class="`icon-${item.icon}`"></i>
        </div>
        <div class="text flex-1" >{{item.text}}</div>
        <div>
          <Icon name="arrow" size="16" color="#666"></Icon>
        </div>
      </div>
    </div>

    <div class="logout">
      <Button @click="logout">{{i18n.t('mine.btn')}}</Button>
    </div>
  </div>
</template>

<script>
import { userInfo } from '@/api/user'
import { Icon, Button } from 'vant'
import { removeToken } from '@/utils/auth'
export default {
  name: 'mine',
  components: {
    Icon
  },
  data() {
    return {
      header: 1,
      userName: '',
      userMoney: '0.00',
      userPhone: '',
      level: '',
      levelName: '',
      rechargeUrl: require('../../assets/imgs/recharge.png'),
      withdrawUrl: require('../../assets/imgs/withdraw.png'),
      vip1: {backgroundImage:"url(" + require("../../assets/imgs/bg/vip1.png") + ")"},
      vip2: {backgroundImage:"url(" + require("../../assets/imgs/bg/lv2-bg.png") + ")"},
      vip3: {backgroundImage:"url(" + require("../../assets/imgs/bg/lv3-bg.png") + ")"},
      vip4: {backgroundImage:"url(" + require("../../assets/imgs/bg/lv4-bg.png") + ")"},
      vip5: {backgroundImage:"url(" + require("../../assets/imgs/bg/lv5-bg.png") + ")"},
      vip6: {backgroundImage:"url(" + require("../../assets/imgs/bg/lv6-bg.png") + ")"},
      vip:[
          require("../../assets/imgs/bg/tmp.png"),
          require("../../assets/imgs/bg/vip2.png"),
          require("../../assets/imgs/bg/vip3.png"),
          require("../../assets/imgs/bg/vip4.png"),
          require("../../assets/imgs/bg/vip5.png"),
          require("../../assets/imgs/bg/vip6.png"),
      ],
      langListBak: [
        {
          icon: require('../../assets/imgs/lang/uk.webp'),
          key: 'uk'
        },
        {
          icon: require('../../assets/imgs/lang/zh.png'),
          key: 'zh-CN'
        },
        {
          icon: require('../../assets/imgs/lang/en.png'),
          key: 'en'
        },

        {
          icon: require('../../assets/imgs/lang/ar.png'),
          key: 'ar'
        },
        {
          icon: require('../../assets/imgs/lang/es.png'),
          key: 'es'
        },
        {
          icon: require('../../assets/imgs/lang/fr.png'),
          key: 'fr'
        },
        {
          icon: require('../../assets/imgs/lang/id.png'),
          key: 'id'
        },
        {
          icon: require('../../assets/imgs/lang/ja.png'),
          key: 'ja'
        },
        {
          icon: require('../../assets/imgs/lang/ko.png'),
          key: 'ko'
        },
        {
          icon: require('../../assets/imgs/lang/pt.png'),
          key: 'pt'
        },
        {
          icon: require('../../assets/imgs/lang/ru.png'),
          key: 'ru'
        },
        {
          icon: require('../../assets/imgs/lang/th.png'),
          key: 'th'
        },
        {
          icon: require('../../assets/imgs/lang/he.png'),
          key: 'he'
        },
        {
          icon: require('../../assets/imgs/lang/tr.png'),
          key: 'tr'
        },
        {
          icon: require('../../assets/imgs/lang/hi.png'),
          key: 'hi'
        },
        {
          icon: require('../../assets/imgs/lang/vi.png'),
          key: 'vi'
        },
        {
          icon: require('../../assets/imgs/lang/de.png'),
          key: 'de'
        },
        {
          icon: require('../../assets/imgs/lang/it.png'),
          key: 'it'
        },
        {
          icon: require('../../assets/imgs/lang/kk.png'),
          key: 'kk'
        },
        {
          icon: require('../../assets/imgs/lang/zh.png'),
          key: 'zh-TW'
        },
        {
          icon: require('../../assets/imgs/lang/ka.svg'),
          key: 'ka'
        },
        {
          icon: require('../../assets/imgs/lang/ro.svg'),
          key: 'ro'
        },
        {
          icon: require('../../assets/imgs/lang/bg.svg'),
          key: 'bg'
        },
        // 亚美尼亚语
        {
          icon: require('../../assets/imgs/lang/hy.png'),
          key: 'hy'
        },
        {
          icon: require('../../assets/imgs/lang/pl.png'),
          key: 'pl'
        },
        // 立陶宛语
        {
          icon: require('../../assets/imgs/lang/lt.png'),
          key: 'lt'
        },
          // 斯洛伐克语
        {
          icon: require('../../assets/imgs/lang/sk.svg'),
          key: 'sk'
        },
        {
          icon: require('../../assets/imgs/lang/fa.png'),
          key: 'fa'
        },
        // 阿塞拜疆语
        {
          icon: require('../../assets/imgs/lang/az.png'),
          key: 'az'
        },
      ],
      langList: [],
      lang: '',
      langIcon: '',
      showLang: false,
    }
  },
  computed: {
    appName() {
      return this.$store.state.appName
    },
    avatar() {
      return this.$store.state.avatar
    },
    withdraw_bind_address() {
        return this.$store.state.withdraw_bind_address
    },
    withdraw_bind_bank() {
      return this.$store.state.withdraw_bind_bank
    },
    language() {
      return this.$store.state.language
    },
    menuList() {
      let arr = [

        {
          icon: 'scan',
          text: this.i18n.t('mine.menu2'),
          path: 'share'
        },
        {
          icon: 'order',
          text: this.i18n.t('mine.menu3'),
          path: 'order'
        },
        {
          icon: 'wallet',
          text: this.i18n.t('mine.menu4'),
          path: 'record'
        },
        {
          icon: 'report',
          text: this.i18n.t('mine.menu5'),
          path: 'team'
        },
        {
          icon: 'company',
          text: this.i18n.t('mine.menu6'),
          path: 'company'
        },
      ]
        if(parseInt(this.withdraw_bind_bank,10) === 1) {
            arr.unshift({
                icon: 'address',
                text: this.i18n.t('mine.menu7'),
                path: 'bind'
            })
            arr.unshift({
              icon: 'address',
              text: this.i18n.t('mine.menu8'),
              path: 'bindCard'
            })
        } else if(this.withdraw_bind_address == 1 ) {
          arr.unshift({
            icon: 'address',
            text: this.i18n.t('mine.menu7'),
            path: 'bind'
          })
        } else {
            arr.unshift({
                icon: 'address',
                text: this.i18n.t('mine.menu1'),
                path: 'address'
            })
        }

        return arr
    }
  },
  watch: {
    language(val) {
      this.initLang()
    }
  },
  mounted() {
    this.init()
    if (this.language) {
      this.initLang()
    }
  },
  methods: {
    initLang() {
      this.langList = this.langListBak.filter(v => {
        return this.language.indexOf(v.key) > -1
      })
      this.lang = localStorage.getItem('locale') || 'en'
      let index = this.langList.findIndex(v => {
        return this.lang == v.key
      })
      if (index > -1) {
        this.langIcon = this.langList[index].icon
      }
    },
    toPath(path) {
      this.$router.push({
        name: path
      })
    },
    init() {
      userInfo().then(res => {
        this.$store.state.avatar = res.data.Avatar || 1
        this.userName = res.data.Nickname
        this.userPhone = res.data.UserName
        this.userMoney = res.data.UserAccount.Balance
        this.levelName = res.data.Leave.Leave
      })
    },

    toPage(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
    toLang() {
      this.showLang = true
    },
    changeLang(data) {
      this.showLang = false
      this.i18n.locale = data.key
      localStorage.setItem('locale', data.key)
      this.langIcon = data.icon
    },
    logout() {
      removeToken()
      this.$router.push({
        name: 'login'
      })
    }
  }
}
</script>
<style lang="less">

</style>
